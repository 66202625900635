import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../../components/Layout";
import { Container } from "react-bootstrap";
import { Seo } from "../../utils/Seo";

function BlogPost({ data }) {
  const post = data.markdownRemark.frontmatter;
  const description = data.markdownRemark.html;
  const formattedDescription = description
    .replace(/<\/?[^>]+(>|$)/g, "")
    .slice(0, 250);
  return (
    <Layout pageClass="blog-page">
      <Seo
        title={`${post.title} | Montebello.digital`}
        desc={`${formattedDescription}...`}
      />
      <section className="blog-section">
        <Container>
          {/* <div>
            <img className="blog-section--img" src="http://localhost:8000/static/online-shopping-60fd33e173560595205ad462b39b22df.png" />
          </div> */}
          <p className="mb-0 mt-0">
            <Link to="/blog" className="btn-link">
              {"← "} Back to Blog
            </Link>
          </p>
          <h1 className="blog-title mb-3">{post.title}</h1>
          <p className="blog-subline">
            <span className="ml-0 blog-category">{post.topic}</span>
          </p>

          <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
        </Container>
      </section>
      <div className="blog-page-navigation text-center">
        <Link to="/blog" className="btn">
          {"← "} Back To Blog
        </Link>
      </div>
    </Layout>
  );
}

export default BlogPost;

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        date(formatString: "MM-DD-YYYY")
        topic
        title
      }
      html
    }
  }
`;
